/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Blank, CheckBox, Column, Div, EvButton, Font, Form, Hr, Input, LineInputLabel, Row, WrapRegister } from "../../styles/commonStyle";
import { GreyBorderBox, Li, Ul } from "./style";
import { useEffect, useState } from "react";
import InputLine from "../../components/input/inputLine";
import { FieldErrors, FieldValues, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useRecoilState } from "recoil";
import { carSelector } from "../../recoil/variableAtom";
import { RESULT, ResponseModel } from "../../model/response";
import { registerChangeWidth } from "../../styles/theme";
import { Option } from "react-dropdown";
import useFetch from "../../hooks/useFetch";
import { Car } from "../../model/car";
import { errorState, loadingState, modalState, modalTypeState, smatroReigistBillingState, userRegistIngState, userRegistState } from "../../recoil/atoms";
import { TodayFormatYYYYMMDDHHmmssSSS, ediDateNoFormat, makeEncryptData, makeHyphenInput, makeMoid, paycardHypen, todayNoFoarmat } from "../../util";
import { InfoText, Status } from "./components/infoText";

function MemberRegisterForm({
  register,
  errors,
  setValue,
  getValues,
  carCompanys,
}: {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  carCompanys?: string[];
}) {
  const [modalType, setModalType] = useRecoilState(modalTypeState);
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [getCars, setCars] = useState<Option[]>();
  const [getCheckList, setCheckList] = useState<boolean[]>([false, false]);
  const [errMsg, setErrMsg] = useRecoilState(errorState);

  const [carCompanyNm, setCarCompanyNm] = useState<any>();
  const getCarsApi: ResponseModel<Car[]> = useFetch({
    recoilSelector: carSelector,
    query: carCompanyNm,
  });
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const [userRegistIng, setUserRegistIng] = useRecoilState(userRegistIngState);
  const [smatroReigistBilling, setSmatroReigistBilling] = useRecoilState(smatroReigistBillingState);


  const [smartroScriptLoading, setSmartroScriptLoading] = useState(false);

  const [Moid, setMoid] = useState("");
  const [EdiDate, setEdiDate] = useState("");
  const [EncryptData, setEncryptData] = useState("");

  const [isPayCard, setIsPayCard] = useState(false);

  const requstPay = () => {
    // @ts-ignore
    window.smartropay.init({
      // mode: "STG",
      mode: "REAL",
      actionUri: '/ssb/interface.do'
    });

    // @ts-ignore
    window.smartropay.payment({
      FormId: 'paycardForm'
    });
  }

  const onClickChangePaycardHandler = () => {
    setUserRegistIng(getValues());
    requstPay();
  }

  const modalHandler = (type: string) => {
    setModalType(type);
    setModalOpen(true);
  };

  const getCarModelCompanyInfo = (e: any) => {
    setValue(
      "carModelCompany",
      e.value
      // { shouldValidate: true }
    );
    setCarCompanyNm(getValues("carModelCompany"));
    setValue(
      "carModelId",
      {
        label: "선택없음",
        value: "None",
      }
      // { shouldValidate: true }
    );
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://pay.smartropay.co.kr/asset/js/SmartroPAY-1.0.min.js?version=" + todayNoFoarmat();
    script.async = false;
    script.addEventListener('load', () => setSmartroScriptLoading(true));
    document.body.appendChild(script);
    setLoading(false)
  }, []);

  useEffect(() => {
    if (!smartroScriptLoading) {
      return;
    } else {
      const moid = makeMoid();
      const ediDate = ediDateNoFormat();
      setMoid(moid);
      setEdiDate(ediDate);
      setEncryptData(makeEncryptData(ediDate, moid));
    }
  }, [smartroScriptLoading]);



  useEffect(() => {
    if (getCarsApi === undefined || carCompanyNm === undefined) {
      return;
    }

    if (getCarsApi.result === RESULT.OK) {
      let tempCarList: Option[] = [
        {
          label: "선택없음",
          value: "None",
        },
        ...getCarsApi.data!.map((value: Car) => {
          return { label: value.model_nm, value: value.id.toString() };
        }),
      ];
      setCars(tempCarList);
    } else {
      setErrMsg(getCarsApi.msg!);
      setModalOpen(true);
    }
  }, [getCarsApi]);

  useEffect(() => {
    setValue("smsRcptnAgreYn", getCheckList[0]);
    setValue("emlRcptnAgreYn", getCheckList[1]);
  }, [getCheckList]);


  useEffect(() => {
    if (userRegistIng === undefined) return;
    Object.keys(userRegistIng).forEach(key => {
      setValue(key, userRegistIng[key])
    })
  }, [userRegistIng])



  useEffect(() => {
    if (smatroReigistBilling === undefined) return;
    const cardNo = paycardHypen(smatroReigistBilling['DisplayCardNo']);
    setValue('hiddenPaycardNo', cardNo)
    if (cardNo) {
      setIsPayCard(true);
    }
  }, [smatroReigistBilling, setValue])

  useEffect(() => {
    const hiddenPaycardNo = getValues('hiddenPaycardNo');
    if (hiddenPaycardNo) {
      setIsPayCard(true);
    }
  }, [getValues]);

  return (
    <><WrapRegister className="w-full justify-between">
      <GreyBorderBox>
        <InputLine
          label="아이디"
          labelWidth="25%"
          name="lgnId"
          type="text"
          necessary={true}
          placeholder="3-20글자 영문,숫자를 입력해주세요."
          register={register}
          errors={errors.lgnId ? errors.lgnId.message : undefined}
          noBorder={true}
          setValue={setValue}
          tabIndex={1}
        ></InputLine>
        <Hr className="my-[10px]" />
        <InputLine
          label="성명"
          labelWidth="25%"
          name="nm"
          type="text"
          necessary={true}
          placeholder="이름을 입력해주세요."
          register={register}
          errors={errors.nm ? errors.nm.message : undefined}
          noBorder={true}
          setValue={setValue}
          tabIndex={2}
        ></InputLine>
        <Hr className="my-[10px]" />
        <Div className="w-full flex items-center">
          <InputLine
            label="휴대전화"
            labelWidth="calc(25% + 40.45px)"
            name="phone"
            type="text"
            necessary={true}
            placeholder="클릭하여 입력해주세요."
            register={register}
            errors={errors.phone ? errors.phone.message : undefined}
            noBorder={true}
            onClick={() => modalHandler("phone")}
            setValue={setValue}
            readOnly={true}
            tabIndex={3}
          ></InputLine>
          <Div isdisable="true">
            <EvButton
              type="button"
              className="mx-[1.5rem] h-[2.5rem]"
              padding="0.1rem 1rem"
              fontSize="1rem"
              bgColor="#808184"
              color="#FFF"
              onClick={() => modalHandler("phone")}
            >
              인증
            </EvButton>
            {errors.phone ? <Blank height="1.5rem" /> : null}
          </Div>
        </Div>
        <Hr className="my-[10px]" />
        <InputLine
          label="이메일"
          labelWidth="25%"
          name="email"
          type="text"
          necessary={true}
          placeholder="이메일을 입력해주세요."
          register={register}
          errors={errors.email ? errors.email.message : undefined}
          noBorder={true}
          setValue={setValue}
          tabIndex={4}
        ></InputLine>
        <Hr className="my-[10px]" />
        <InputLine
          label="비밀번호"
          labelWidth="25%"
          name="password"
          type="password"
          necessary={true}
          placeholder="특수문자 포함 영문 8~20글자를 입력해주세요."
          register={register}
          errors={errors.password ? errors.password.message : undefined}
          noBorder={true}
          setValue={setValue}
          tabIndex={5}
        ></InputLine>
        <Hr className="my-[10px]" />
        <InputLine
          label="비밀번호확인"
          labelWidth="25%"
          name="passwordConfirmation"
          type="password"
          necessary={true}
          placeholder="비밀번호를 재입력해주세요."
          register={register}
          errors={errors.passwordConfirmation ? errors.passwordConfirmation.message : undefined}
          noBorder={true}
          setValue={setValue}
          tabIndex={6}
        ></InputLine>
        <Hr className="my-[10px]" />
        <InputLine
          label="배송지 주소"
          labelWidth="25%"
          name="rdnmadr"
          type="adres"
          necessary={true}
          placeholder="클릭하여 입력해주세요."
          register={register}
          errors={errors.rdnmadr ? errors.rdnmadr.message : undefined}
          noBorder={true}
          setValue={setValue}
          readOnly={true}
          tabIndex={7}
        ></InputLine>
        <Hr className="my-[10px]" />
        <InputLine
          label="배송지 상세주소"
          labelWidth="25%"
          name="adres_detail"
          type="text"
          necessary={true}
          placeholder=""
          register={register}
          errors={errors.adres_detail ? errors.adres_detail.message : undefined}
          noBorder={true}
          setValue={setValue}
          tabIndex={8}
        ></InputLine>
        <InfoText status={Status.WARNING} text="회원카드가 발송되는 주소입니다. 정확한 주소를 기입해 주시기 바랍니다." />
      </GreyBorderBox>
      <Column width="50%" changeWidth="100%" mediaWidth={registerChangeWidth}>
        <GreyBorderBox width="100%">
          <InputLine
            label="차량제조사"
            labelWidth="25%"
            name="carModelCompany"
            type="dropdown"
            necessary={true}
            placeholder=""
            register={register}
            errors={errors.carModelCompany ? errors.carModelCompany.message : undefined}
            noBorder={true}
            options={carCompanys ?? ["선택없음"]}
            setValue={setValue}
            getValues={getValues}
            onChange={getCarModelCompanyInfo}
          ></InputLine>
          <Hr className="my-[10px]" />
          <InputLine
            label="차량정보"
            labelWidth="25%"
            name="carModelId"
            type="dropdown"
            necessary={true}
            placeholder=""
            register={register}
            errors={errors.carModelId ? errors.carModelId.message : undefined}
            noBorder={true}
            options={
              getCars ?? [
                {
                  label: "선택없음",
                  value: "None",
                },
              ]
            }
            setValue={setValue}
            getValues={getValues}
            onChange={(e: any) => setValue("carModelId", e, { shouldValidate: true })}
          ></InputLine>
          <Hr className="my-[10px]" />
          <InputLine
            label="차량번호"
            labelWidth="25%"
            name="carNo"
            type="text"
            necessary={true}
            placeholder="대표 차량번호를 입력해주세요."
            register={register}
            errors={errors.carNo ? errors.carNo.message : undefined}
            noBorder={true}
            setValue={setValue}
            tabIndex={9}
          ></InputLine>
          <Hr className="my-[10px]" />
        </GreyBorderBox>
        <Blank height="10px"></Blank>
        <Form id="paycardForm" name="paycardForm" method="post">
          <input type="hidden" name="PayMethod" value="CARD" placeholder="" />
          <input type="hidden" name="Mid" maxLength={10} value={process.env.REACT_APP_SMARTRO_MID} placeholder="" />
          <input type="hidden" name="Moid" maxLength={40} value={Moid} placeholder="특수문자 포함 불가" />
          <input type="hidden" name="MallUserId" maxLength={20} value={TodayFormatYYYYMMDDHHmmssSSS()} placeholder="" />
          <input type="hidden" name="MallIp" maxLength={20} value="10.0.0.1" placeholder="" />
          <input type="hidden" name="UserIp" maxLength={20} value="10.0.0.1" placeholder="" />
          <input type="hidden" name="ReturnUrl" size={100} className="input" value={process.env.REACT_APP_API_URL + `/api/v1/users/register/result?company=false`} />
          <input type="hidden" name="VerifyValue" maxLength={100} className="input" value={EncryptData} placeholder="위/변조방지 HASH 데이터" />
          <input type="hidden" name="EncodingType" maxLength={14} value="utf-8" placeholder="utf-8 or euc-kr" />
          <input type="hidden" name="SspMallId" maxLength={20} value={process.env.REACT_APP_SMARTRO_SSPMALLID} placeholder="테스트용 SspMallId" />
          <input type="hidden" name="EdiDate" maxLength={14} value={EdiDate} placeholder="" />
          <input type="hidden" name="IsPwdPass" maxLength={1} value="Y" placeholder="" />
        </Form>
        <GreyBorderBox width="100%">
          <InputLine
            label="결제카드 정보"
            labelWidth="25%"
            name="hiddenPaycardNo"
            type="text"
            necessary={true}
            placeholder="클릭하여 입력해주세요."
            register={register}
            errors={errors.hiddenPaycardNo ? errors.hiddenPaycardNo.message : undefined}
            noBorder={true}
            onClick={() => {
              onClickChangePaycardHandler();
            }}
            readOnly={true}
            setValue={setValue}
          // disabled={true}
          ></InputLine>
          <Input type="hidden" {...register("paycardNo")} name="paycardNo" />
          <Input type="hidden" {...register("paycardExp")} name="paycardExp" />
          <Input type="hidden" {...register("paycardBirth")} name="paycardBirth" />
          <Input type="hidden" {...register("paycardPw")} name="paycardPw" />
          {
            isPayCard ? <InfoText status={Status.INFO} text="멤버십카드로 충전 결제시, 이 카드로 결제됩니다." /> : null
          }
        </GreyBorderBox>

        <Blank height="10px"></Blank>

        <GreyBorderBox width="100%">
          <LineInputLabel className="p-[0.5rem]" width="100%">마케팅·광고성 메시지 수신 동의</LineInputLabel>
          <LineInputLabel className="p-[0.5rem]" width="100%" style={{ color: "#9CA3AF", fontSize: '13px' }} >evPlug는 수신에 동의한 회원에 한하여 이메일, SMS 등의 방법으로 충전요금 할인 이벤트 등 다양한 정보를 전송할 수 있습니다.
            광고 수신을 원하지 않을 경우 언제든지 수신을 거부할 수 있습니다.</LineInputLabel>
          <Row className="w-full p-[0.5rem]">
            <label className="w-[50%]">
              <Font className="w-full flex items-center" fontSize="calc(0.7rem + 0.3vw)">
                <CheckBox
                  type="checkbox"
                  {...register("smsRcptnAgreYn")}
                  name="smsRcptnAgreYn"
                  className="me-[8px]"
                  checked={getCheckList[0]}
                  onChange={() => setCheckList([!getCheckList[0], getCheckList[1]])}
                />
                SMS
              </Font>
            </label>
            <label className="w-[50%]">
              <Font className="w-full flex items-center" fontSize="calc(0.7rem + 0.3vw)">
                <CheckBox
                  type="checkbox"
                  {...register("emlRcptnAgreYn")}
                  name="emlRcptnAgreYn"
                  className="me-[8px]"
                  checked={getCheckList[1]}
                  onChange={() => setCheckList([getCheckList[0], !getCheckList[1]])}
                />
                이메일
              </Font>
            </label>
          </Row>
        </GreyBorderBox>
        <Blank height="calc(1rem + 1vw)" />
      </Column>
      {/* <EvModal type={modalType} setValue={setValue} /> */}
    </WrapRegister>
      <Blank height="calc(1rem + 1vw)" />
      <GreyBorderBox width="100%">
        <Div className="w-full p-3" >
          <Font fontSize="calc(0.5rem + 0.4vw)">* 주의사항</Font>
          <Ul className="list-disc w-full" listPosition="inside" >
            <Li fontColor="#DD4145" style={{ 'fontWeight': '600' }}>회원가입 시 멤버십카드는 자동으로 발급되어 입력하신 주소로 발송됩니다.</Li>
            <Li>회원가입 시 발급되는 멤버십카드는 분실 및 파손 시 서비스 담당자를 통해 재발급 받으실 수 있고, 재발급 비용은 별도 청구됩니다.</Li>
            <Li>배송지 주소와 배송지 상세주소를 오기입해 멤버십카드 분실 시 재발급 가능하나, 비용은 별도 청구됩니다.</Li>
          </Ul>
        </Div>
      </GreyBorderBox>
    </>
  );

}

export default MemberRegisterForm;
